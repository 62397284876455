import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Users from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";

export default function Dashboard() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                {/* <div className="d-flex justify-content-end align-items-center">
                  <div className="select-box">
                    <Form.Select aria-label="Default select example">
                      <option>All Time</option>
                      <option value="1">Active</option>
                    </Form.Select>
                  </div>
                </div> */}
                <Row>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>Total Users</h6>
                      <h4>00</h4>
                      <p>Total Average users per day 0</p>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>Total Matches </h6>
                      <h4>00</h4>
                      <p>Total Average matches  per day 0</p>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>Total Subscribers</h6>
                      <h4>00</h4>
                      <p>Total Average subscribers  per day 0</p>
                    </div>
                  </Col>
                  {/* <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL PROPERTY MANAGERS</h6>
                      <h4>30 Millions</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
            {/* <Col xxl={3} xl={4} lg={3}>
                  <div className="dashbox-side">
                    <h2>ACCOUNTS OVERVIEW</h2>
                    <div className="dash-inner-boxes">
                      <h6>ACCOUNT CURRENT </h6>
                      <h4>£2,202,330.00</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
          </Row>

          <Row className="dash-bottom mb-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>TOTAL USERS</h6>
                <Users />
              </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>TOTAL DEVICES ACCESS </h6>
                <Orders />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
