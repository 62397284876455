import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
// import UserActive from "./pages/UserActive";
import CustomerProfile from "./pages/CustomerProfile";

import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import AllReports from "./pages/AllReports";
import ReportDetails from "./pages/ReportDetails";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import UserActive from "./pages/UserActive";
import EditNotification from "./pages/EditNotification";

import Usersuspend from "./pages/UserSuspend";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
          <Route path="/Supportchat" element={<Supportchat />} />
          {/* <Route path="/UserActive" element={<UserActive />} /> */}
          <Route path="/Notification" element={<Notification />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/AllReports" element={<AllReports />} />
          <Route path="/ReportDetails" element={<ReportDetails />} />
          <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />
          <Route path="/ActiveUser" element={<UserActive />} />
          <Route path="/SuspendUser" element={<Usersuspend />} />
          <Route path="/EditNotification" element={<EditNotification />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
