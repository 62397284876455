import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function ReportDetails() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Report Details</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/AllReports">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="">
          <Col lg={8}>
            <div className="customer-form-new">
              <h2>Report Details</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Row>

                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Reported By:</Form.Label>
                      <Form.Control
                        type="text"
                        value="Anna"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Reported Against:</Form.Label>
                      <Form.Control
                        type="text"
                        value="Anna"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. "
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={4}>
                <div className="product-overview-right">
                  <div className="product-overview-box">
                    <h2>ADMIN ACTIONS</h2>

                  
                    <button type="button" className="edit-product">
                    Close Ticket
                    </button>
                  </div>
                </div>
              </Col>
        </Row>
      </Container>
    </Layout>
  );
}
